<template>
  <div>
    <b-button
      variant="pending"
      :size="size"
      @click="clickModal"
      :disabled="disabled"
      >Plan</b-button
    >
    <b-modal
      v-model="showModal"
      centered
      no-close-on-backdrop
      scrollable
      title="Purchase Plan"
      id="invoice_detail"
      ref="modal"
      size="lg"
    >
      <b-overlay :show="loading">
        <b-row style="padding-bottom: 30px">
          <b-col md="12" class="my-1">
            <b-form-group
              class="supplier required"
              label-for="supplier"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Supplier Name"
            >
              <template v-if="source_type == 1">
                <SelectLoading
                  v-if="options.supplier == 0"
                  :size="0.7"
                ></SelectLoading>
                <sv-select
                  v-model="supplier"
                  :placeholder="$t('page.please_choose')"
                  :options="options.supplier"
                  :reduce="options => options.value"
                  :selectable="options => !options.disabled"
                  label="label"
                >
                  <template
                    slot="option"
                    slot-scope="option"
                    v-if="option.index"
                  >
                    <div class="d-center" :class="'level_' + option.index">
                      <span v-if="option.index == 2">--</span>
                      {{ option.label }}
                    </div>
                  </template>
                </sv-select> </template
              ><template v-else-if="source_type == 2">
                <b-form-input
                  v-model="supplier_text"
                  type="text"
                  :placeholder="this.$t('page.please_choose_input')"
                ></b-form-input>
              </template>
            </b-form-group>
          </b-col>
          <b-col md="12" class="my-1">
            <b-form-group
              class="qty required"
              label-for="status"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Quantity"
            >
              <b-input-group>
                <template v-slot:append>
                  <b-input-group-text> / {{ maxQty }} </b-input-group-text>
                </template>
                <b-form-input
                  v-model="qty"
                  type="number"
                  :min="1"
                  :max="maxQty"
                  :placeholder="this.$t('page.please_choose_input')"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="12" class="my-1">
            <b-form-group
              label-for="delivery_address"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Delivery to"
            >
              <SelectLoading
                v-if="baseConfig.purchase_delivery_to == 0 || 0"
                :size="0.7"
              ></SelectLoading>
              <sv-select
                v-model="delivery_address"
                :placeholder="$t('page.please_choose')"
                :options="baseConfig.purchase_delivery_to"
                :reduce="options => options.value"
                label="label"
              >
                <!-- :clearable="false" -->
              </sv-select>
            </b-form-group>
          </b-col>

          <b-col md="12" class="my-1">
            <b-form-group
              label-for="plan_date"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Plan Date"
              class="required"
            >
              <b-input-group>
                <template v-slot:append>
                  <div
                    class="datepicker-x"
                    v-if="plan_date"
                    @click="initialTime()"
                  >
                    <b-icon-x font-scale="1.45"></b-icon-x>
                  </div>
                  <b-input-group-text>
                    <b-icon-calendar2-date></b-icon-calendar2-date>
                  </b-input-group-text>
                </template>
                <b-form-datepicker
                  v-model="plan_date"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                  }"
                  :hide-header="true"
                  :disabled="plan_date_disabled"
                  locale="de"
                ></b-form-datepicker>
              </b-input-group>
            </b-form-group>
          </b-col>
          <!-- </b-form-group> -->
        </b-row>
      </b-overlay>
      <template v-slot:modal-footer="{ close }">
        <b-button
          variant="primary"
          @click="submit()"
          :disabled="submitDisabled"
        >
          Submit
        </b-button>
        <b-button variant="secondary" @click="close()">
          {{ $t('page.cancel') }}
        </b-button>
      </template></b-modal
    >
  </div>
</template>
<script>
import apiBus from '@/common/apiBus/index';
import SelectLoading from '@/components/Loading/Index';
import { mapState } from 'vuex';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
// import { RENEW_BASE_CONFIG } from '@/store/modules/baseconfig/actions';
export default {
  name: 'Plan',
  data: function() {
    return {
      showModal: false,
      loading: false,
      source_type: 1, // Must be an array reference!
      supplier: null,
      supplier_text: null,
      qty: 1,
      purchase_price: 0,
      delivery_address: null,
      plan_date: null,
      submitDisabled: false,
      plan_date_disabled: false,
      options: {
        source_type: [],
        supplier: [],
        delivery_address: []
      }
    };
  },
  components: { SelectLoading },
  props: ['purchaseTargetId', 'maxQty', 'disabled', 'size', 'initFn'],
  methods: {
    clickModal() {
      console.log(this.options);
      if (this.options.delivery_address.length == 0) {
        this.options.delivery_address = this.baseConfig.delivery_address || [];
      }
      // if (this.options.supplier.length == 0) {
      apiBus.purchase
        .getSupplierList({
          purchase_target_id: this.purchaseTargetId
        })
        .then(data => {
          let options = [];
          Object.keys(data.data.data.supplier_list).forEach(item => {
            options.push({
              label: item,
              value: item.replace(/ /g, '_'),
              index: 1,
              disabled: true
            });
            // console.log(item);
            data.data.data.supplier_list[item].forEach(x => {
              options.push({
                label: x.label,
                value: x.value,
                index: 2
              });
            });
          });
          this.options.supplier = options;
          // 给Order Type赋值
        })
        .catch(error => {
          console.log(error);
        });
      this.source_type = 1;
      this.supplier = null;
      this.supplier_text = null;
      this.qty = 1;
      this.delivery_address = this.baseConfig.purchase_delivery_to[0].value;
      this.showModal = true;
      this.plan_date = null;
    },

    submit() {
      console.log('submit');
      if (this.source_type == 1) {
        if (this.supplier == null || this.supplier == '') {
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Fields marked with * are required!' // 提示内容
          });
          return false;
        }
      }
      if (
        this.qty == null ||
        this.qty == '' ||
        this.plan_date == null ||
        this.plan_date == ''
      ) {
        this.$store.dispatch(PROMPT_STATUS, {
          dismissCountDown: 5, //初始提示作用于定时器
          alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
          alertText: 'Fields marked with * are required!' // 提示内容
        });
      } else {
        console.log(!Number.isInteger(this.purchase_price));
        if (
          isNaN(Number(this.qty)) ||
          this.qty == 'e' ||
          this.qty > this.maxQty ||
          this.qty <= 0
        ) {
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Please fill in Quantity correctly!' // 提示内容
          });
        } else {
          this.submitDisabled = true;
          this.loading = true;
          apiBus.purchase
            .purchaseProduct({
              purchase_target_id: this.purchaseTargetId,
              supplier: this.supplier,
              qty: this.qty,
              purchase_price: this.purchase_price,
              delivery_address: this.delivery_address
            })
            .then(data => {
              console.log(data);
              this.submitDisabled = false;
              this.loading = false;
              this.showModal = false;
              this.$store.dispatch(PROMPT_STATUS, {
                dismissCountDown: 5, //初始提示作用于定时器
                alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
                alertText: 'Successfully!' // 提示内容
              });
              if (document.getElementById('purchase-overview-list')) {
                var scroll_top = 0;
                if (
                  document.documentElement &&
                  document.documentElement.scrollTop
                ) {
                  scroll_top = document.documentElement.scrollTop;
                } else if (document.body) {
                  scroll_top = document.body.scrollTop;
                }
                var divTop = document
                  .getElementById('purchase-overview-list')
                  .getBoundingClientRect().top;
                console.log(divTop);
                window.scrollTo(
                  0,
                  divTop +
                    scroll_top -
                    document.documentElement.clientHeight / 3
                );
              }
              this.showModal = false;
              this.initFn(1);
            })
            .catch(error => {
              this.submitDisabled = false;
              this.loading = false;
              this.$store.dispatch(PROMPT_STATUS, {
                dismissCountDown: 5, //初始提示作用于定时器
                alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
                alertText:
                  error.data == undefined ? error.message : error.data.message // 提示内容
              });
            });
        }
      }
    },

    initialTime() {
      this.purchase_date = null;
    },

    init() {
      // if (Object.keys(this.baseConfig).length == 0) {
      //   apiBus.purchase
      //     .getBaseConfig()
      //     .then((data) => {
      //       console.log('getBaseConfig数据缓存vuex');
      //       this.$store.dispatch(RENEW_BASE_CONFIG, data.data.data);
      //       // 给Order Type赋值
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });
      // }
    }
  },
  computed: {
    ...mapState({
      baseConfig: state => state.baseconfig.baseconfig
    })
  },
  mounted() {
    this.init();
  }
};
</script>
<style scoped></style>
